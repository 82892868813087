import { useState } from 'react';
import { Text, TextField } from 'infinitytechnologies-ui';

import { Switch } from '@mui/material';

import { useContextState } from '@/separatedModules/pages/Locations/components/Providers';

import { Flex } from '@/separatedModules/components';

export const Step2 = () => {
  const { setState } = useContextState();

  const [currentValue, setCurrentValue] = useState('');
  const [isCm, setIsCm] = useState(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCm(event.target.checked);
    setState((state) => ({
      ...state,
      size: event.target.checked ? convertToPixels(currentValue) : currentValue
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/[^0-9.]/g, '');
    setCurrentValue(inputValue);

    if (isCm) {
      setState((state) => ({
        ...state,
        size: convertToPixels(inputValue)
      }));
    } else {
      setState((state) => ({
        ...state,
        size: inputValue
      }));
    }
  };

  const convertToPixels = (value: string) => {
    if (!value) return '';
    const cmToPxFactor = 37.7952755906;
    return (parseFloat(value) * cmToPxFactor).toFixed(0);
  };

  const getFormattedValue = () => {
    if (!currentValue) return '';
    return `${currentValue}${isCm ? ' cm' : ' px'}`;
  };

  return (
    <Flex flexDirection="column">
      <Text
        sx={{
          fontSize: '14px',
          fontWeight: 600,
          marginBottom: '11px'
        }}
      >
        QR Code Size Settings
      </Text>
      <Text
        sx={{
          fontSize: '12px',
          fontWeight: 400
        }}
      >
        Select the optimal size for your QR code before generating it.
      </Text>
      <TextField
        name={'size'}
        label={`Value in ${isCm ? 'centimeter' : 'pixels'}`}
        value={getFormattedValue()}
        style={{ height: 38 }}
        sx={{
          maxWidth: '210px',
          marginTop: '40px',
          '.MuiInputBase-root': {
            height: '40px',
            input: {
              padding: 0,
              fontSize: '14px',
              paddingLeft: '16px',
              paddingRight: '16px'
            }
          },
          '.MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            transform: 'translate(16px, 9px) scale(1)',
            fontSize: '14px',
            color: '#6A7A94'
          }
        }}
        onChange={handleInputChange}
      />
      <Flex marginTop="6px" alignItems="center" marginBottom="32px">
        <Switch size={'small'} checked={isCm} onChange={handleSwitchChange} />
        <Text
          sx={{
            fontSize: '12px',
            marginLeft: '8px',
            fontWeight: 500,
            color: '#6A7A94'
          }}
        >
          Value in centimeter
        </Text>
      </Flex>
    </Flex>
  );
};
