import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { CloseIcon, ScheduleIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface MobileActionsProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  taskName: string;
}

export const MobileActions = ({ isOpen, onClose, onOpen, taskName }: MobileActionsProps) => {
  const { t: tTasks } = useTranslation('tasks');
  const { taskId = '' } = useParams();

  const navigateTo = useNavigate();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        sx: { borderTopRightRadius: '20px', borderTopLeftRadius: '20px' }
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box
            sx={{
              width: '32px',
              height: '4px',
              backgroundColor: '#5C6C83',
              opacity: '0.4',
              borderRadius: '100px',
              margin: '16px'
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '40px',
            position: 'relative',
            alignItems: 'center'
          }}
        >
          <Text m={0} sx={{ fontSize: '18px', fontWeight: '600' }}>
            {tTasks('mobile.actions')}
          </Text>
          <CloseIcon
            sx={{
              fontSize: '24px',
              position: 'absolute',
              right: '12px',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
            onClick={onClose}
          />
        </Box>
        <Box sx={{ marginBottom: '70px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px'
            }}
            onClick={() => navigateTo(`/tasks/${taskId}/request-on-hold?taskName=${encodeURIComponent(taskName)}`)}
          >
            <ScheduleIcon sx={{ width: '24px', paddingRight: '6px' }} />
            <Text sx={{ fontSize: '16px' }}>{tTasks('mobile.requestOnHold')}</Text>
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
