import Box from '@mui/material/Box';

import { FilterIcon, SearchAutocomplete, SortIcon } from '@/separatedModules/components';

interface SearchWithFiltersProps {
  handleOpenFilter: () => void;
  handleOpenSort: () => void;
  handleSearch: (value: string) => void;
}

export const SearchWithFilters = ({ handleOpenFilter, handleOpenSort, handleSearch }: SearchWithFiltersProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: '#FFF',
        alignItems: 'center',
        height: '40px',
        margin: '8px 16px 16px 16px'
      }}
    >
      <SearchAutocomplete
        placeholder={'Search by Task title'}
        options={[]}
        sx={{ margin: '0', backgroundColor: '#FFF' }}
        onChange={handleSearch}
        onClear={() => handleSearch('')}
      />
      <FilterIcon sx={{ fontSize: '24px', backgroundColor: '#FFF', marginLeft: '16px' }} onClick={handleOpenFilter} />
      <SortIcon sx={{ fontSize: '24px', backgroundColor: '#FFF', marginLeft: '16px' }} onClick={handleOpenSort} />
    </Box>
  );
};
