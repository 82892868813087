import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledTableAside = styled(Box)(({ isAsideOpen }) => ({
  position: 'sticky',
  top: '0',
  height: 'calc(100vh - 64px)',
  width: '505px',
  overflowY: 'auto',
  zIndex: '1000',
  display: isAsideOpen ? 'block' : 'none',

  '.MuiCollapse-root': {
    height: '100%'
  },
  '.MuiCollapse-wrapperInner': {
    borderRadius: 'var(--4, 4px)',
    background: 'var(--background-neutral-hovered, #F7F8F9)',
    padding: '20px',
    margin: '0 0 0 32px',
    flex: '0 0 300px',
    width: '300px !important',
    position: 'relative'
  },
  '.aside-table': {
    '&__btn': {
      '&__close': {
        margin: '0 0 0 auto'
      }
    },
    '&__icon': {
      display: 'block',
      margin: '20px auto'
    },
    '&__text': {
      color: 'var(--text-subtle, #505668)',
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '20px'
    }
  }
}));
