import {
  ArchiveIcon,
  BaseMenuOption,
  CompanyIcon,
  CreateNewFolderIcon,
  DriveFileMoveIcon,
  RenameIcon,
  StarOutlineIcon,
  UserShieldSolidIcon
} from '@/separatedModules/components';

import { i18n } from '@/i18n';

export interface LocationFolder {
  id: string;
  name: string;
  active: boolean;
  groupItems?: {
    items: LocationFolder[];
  };
}

export const FIELD_FOLDER_NAME = 'name';

export enum CompaniesActionTypeEnum {
  CreateCompany = 'create-company',
  CreateFolder = 'create-folder',
  ManageAccess = 'manage-access',
  RenameFolder = 'rename-folder',
  AddToFavorites = 'add-to-favorites',
  Activate = 'activate',
  Deactivate = 'deactivate',
  Move = 'move',
  Archive = 'archive'
}

export const checkAndSetActionIcon = (action: CompaniesActionTypeEnum) => {
  switch (action) {
    case CompaniesActionTypeEnum.CreateCompany:
      return <CompanyIcon />;
    case CompaniesActionTypeEnum.CreateFolder:
      return <CreateNewFolderIcon />;
    case CompaniesActionTypeEnum.ManageAccess:
      return <UserShieldSolidIcon />;
    case CompaniesActionTypeEnum.AddToFavorites:
      return <StarOutlineIcon />;
    case CompaniesActionTypeEnum.RenameFolder:
      return <RenameIcon />;
    case CompaniesActionTypeEnum.Move:
      return <DriveFileMoveIcon />;
    case CompaniesActionTypeEnum.Archive:
      return <ArchiveIcon />;
    default:
      return null;
  }
};

interface BaseLocationOption {
  value: CompaniesActionTypeEnum;
  disabled?: boolean;
}

export type ModifiedBaseMenuOption = Omit<BaseMenuOption, 'value'> & BaseLocationOption;

const getTranslations = (key: string) => {
  return i18n.t(key, { ns: 'company' });
};

export const locationActionsOptions = (): ModifiedBaseMenuOption[] => [
  {
    label: getTranslations('actions.menuList.createCompany'),
    key: '',
    value: CompaniesActionTypeEnum.CreateCompany,
    disabled: true
  },
  {
    label: getTranslations('actions.menuList.createFolder'),
    key: '',
    value: CompaniesActionTypeEnum.CreateFolder,
    disabled: false
  },
  {
    label: getTranslations('actions.menuList.manageAccess'),
    key: '',
    value: CompaniesActionTypeEnum.ManageAccess,
    disabled: false
  },
  {
    label: getTranslations('actions.menuList.addToFavorites'),
    key: '',
    value: CompaniesActionTypeEnum.AddToFavorites,
    disabled: true
  },
  {
    label: getTranslations('actions.menuList.rename'),
    key: '',
    value: CompaniesActionTypeEnum.RenameFolder,
    disabled: false
  },
  {
    label: getTranslations('actions.menuList.move'),
    key: '',
    value: CompaniesActionTypeEnum.Move,
    disabled: false
  },
  {
    label: getTranslations('actions.menuList.archive'),
    key: '',
    value: CompaniesActionTypeEnum.Archive,
    disabled: true
  }
];

export const locationRootActionsOptions = (): ModifiedBaseMenuOption[] => [
  {
    label: getTranslations('actions.menuList.createCompany'),
    key: '',
    value: CompaniesActionTypeEnum.CreateCompany,
    disabled: true
  },
  {
    label: getTranslations('actions.menuList.createFolder'),
    key: '',
    value: CompaniesActionTypeEnum.CreateFolder,
    disabled: false
  }
];

const MAX_DEPTH = 5;

function getFolderDeep(folderId: string, items: LocationFolder[], currDeep = 1): number {
  for (const item of items) {
    if (item.id === folderId) {
      return currDeep;
    } else if (item.groupItems) {
      const depth = getFolderDeep(folderId, item.groupItems.items, currDeep + 1);

      if (depth) return depth;
    }
  }
  return 0;
}

function findFolderById(folderId: string, items: LocationFolder[] = []): LocationFolder | undefined {
  for (const item of items) {
    if (item.id === folderId) {
      return item;
    }

    if (item.groupItems && item.groupItems.items.length > 0) {
      const foundFolder = findFolderById(folderId, item.groupItems.items);

      if (foundFolder) {
        return foundFolder;
      }
    }
  }

  return undefined;
}

export function canCreateSubfolder(folderId: string, items: LocationFolder[]): boolean {
  const folder = findFolderById(folderId, items);

  if (folder && folder.active && getFolderDeep(folderId, items) < MAX_DEPTH) {
    return true;
  }

  return false;
}
