import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { AddUserModalClient } from '@/separatedModules/pages/Users/components/AddUser/components/AddUserModal/AddUserModalClient';

import { AddTaskIcon, AddUserIcon, BadgeIcon, EquipmentWheelsIcon, PlusIcon } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

export const CreateButton = () => {
  const { t: tGlobal } = useTranslation('global');

  const navigateTo = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant={'headerBlue'}
        aria-haspopup={'true'}
        aria-controls={open ? 'create user for company' : undefined}
        aria-expanded={open ? 'true' : undefined}
        sx={{
          '.MuiButton-startIcon': {
            marginRight: '4px'
          }
        }}
        startIcon={<PlusIcon sx={{ fill: 'white', height: '18px' }} />}
        disableElevation
        onClick={handleClickButton}
      >
        {tGlobal('header.btn.create')}
      </Button>

      <Menu
        id={'header-create-button-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'header-create-button-menu-button',
          sx: {
            width: 200
          }
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: 200,
              maxHeight: '400px !important'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{
          margin: '12px 0 0 0'
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuList sx={{ paddingTop: 0 }}>
          <MenuItem onClick={() => navigateTo(LINKS_PAGES.tasksCreate)}>
            <AddTaskIcon />

            {tGlobal('header.menuBtnCreate.btnTask')}
          </MenuItem>
        </MenuList>

        <Box
          sx={{
            height: '1px',
            background: 'var(--border-default, rgba(9, 14, 22, 0.15))'
          }}
        />

        <MenuList>
          <Text
            className={'m-0'}
            variant={'detail'}
            sx={{ color: 'var(--text-subtlest, #969AA8)', padding: '8px 12px' }}
          >
            {tGlobal('header.menuBtnCreate.templates')}
          </Text>

          <MenuItem onClick={() => navigateTo(LINKS_PAGES.templatesEquipmentCreate)}>
            <EquipmentWheelsIcon />

            {tGlobal('header.menuBtnCreate.btnEquipment')}
          </MenuItem>

          <MenuItem onClick={() => navigateTo(LINKS_PAGES.templatesRolesCreate)}>
            <BadgeIcon />

            {tGlobal('header.menuBtnCreate.btnRole')}
          </MenuItem>
        </MenuList>

        <Box
          sx={{
            height: '1px',
            background: 'var(--border-default, rgba(9, 14, 22, 0.15))'
          }}
        />

        <MenuList sx={{ paddingBottom: 0 }}>
          <Text
            className={'m-0'}
            variant={'detail'}
            sx={{ color: 'var(--text-subtlest, #969AA8)', padding: '8px 12px' }}
          >
            {tGlobal('header.menuBtnCreate.users')}
          </Text>

          <MenuItem onClick={handleOpenModal}>
            <AddUserIcon />

            {tGlobal('header.menuBtnCreate.btnInviteUser')}
          </MenuItem>
        </MenuList>
      </Menu>
      <AddUserModalClient isModalOpen={isModalOpen} onCloseModal={handleCloseModal} />
    </>
  );
};
