import saveAs from 'file-saver';
import JSZip from 'jszip';

import { Box, Button } from '@mui/material';

import { useTranslation } from '@/i18n';

import { useContextState, useContextUpdateState } from '../../../Providers';

import { Steps } from './Steps';

import { getBreadcrumbs } from './helpers';
import { addFilesToZip, createSticker, generateQRCodeWithText } from './helpers';

import { StyledDialog } from './style';

export const QrCodeModal = () => {
  const { t: tGlobal } = useTranslation('global');

  const { isOpenQrCodeModal, ...state } = useContextState();
  const { handleSetNextStep, handleSetProgressValue, handleCloseQrModal, setQrModalError } = useContextUpdateState();

  const baseUrl = window.location.origin;

  const selectedData = state.bodyDataRows
    .filter((row) => state.selectedItems.includes(row.id || '') && row.checked)
    .map((row) => row.id);

  const generateQRCodes = async () => {
    const zip = new JSZip();
    const totalCount = selectedData.length;

    let cumulativeProgress = 0;

    for (let i = 0; i < totalCount; i++) {
      const id = selectedData[i];
      const qrValue = `${baseUrl}/locations/equipment/${id}`;

      try {
        const item = state.bodyDataRows.find((row) => row.id === id);
        const breadcrumbsLinks = getBreadcrumbs(item?.location)?.slice(1);

        const qrCodePngData = await generateQRCodeWithText(qrValue, +state.size, item?.systemName || '');
        const stickerPngData = await createSticker(item, +state.size, breadcrumbsLinks);

        addFilesToZip(zip, item?.systemName || '', qrCodePngData, stickerPngData, item?.name || '');
      } catch (error) {
        setQrModalError(error.message);
      }

      cumulativeProgress = Math.round(((i + 1) / totalCount) * 100);
      handleSetProgressValue(cumulativeProgress);
    }

    try {
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, 'qrcode.zip');
      handleCloseQrModal();
    } catch (error) {
      setQrModalError(error.message);
    }
  };

  const handleClick = () => {
    if (state.currentStep === 2 && selectedData.length) {
      generateQRCodes();
    }
    handleSetNextStep();
  };

  return (
    <StyledDialog
      headerTitle={'Export QR-code&Sticker'}
      hideHeaderBorder
      isFirstStep={state.currentStep === 1}
      contentFooter={
        <Box display={'flex'} justifyContent={'end'}>
          <Box display={'flex'}>
            <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseQrModal}>
              {state.error ? 'Close' : tGlobal('table.modal.move.btnCancel')}
            </Button>

            {!state.error && (
              <Button
                variant={'contained'}
                sx={{ margin: '0 24px 0 0 !important' }}
                onClick={handleClick}
                disabled={state.currentStep == 3 || (state.currentStep === 2 && !state.size)}
              >
                {state.currentStep >= 2 ? 'Export' : 'Next'}
              </Button>
            )}
          </Box>
        </Box>
      }
      open={isOpenQrCodeModal}
      onClose={handleCloseQrModal}
    >
      <Steps step={`step${state.currentStep}`} />
    </StyledDialog>
  );
};
