export const getBreadcrumbs = (data: any) => {
  const breadcrumbParts = data?.parentsLocationFolders?.map((folder: any) => folder.name).reverse() || [];

  breadcrumbParts.push(data?.name);

  const breadcrumbsLinks = breadcrumbParts
    .filter((item: string | undefined) => item !== undefined)
    .map((item: string) => ({
      titleFallback: item
    }));

  if (breadcrumbsLinks.length > 2) {
    breadcrumbsLinks.splice(1, breadcrumbsLinks.length - 2, { titleFallback: '...' });
  }

  return breadcrumbsLinks;
};
