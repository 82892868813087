import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { QRCodeIcon } from '@/separatedModules/components/Icon/svg-mui-theme/QRCode';

import { NavBarItems } from './helpers';

export const BottomNavigationBar = () => {
  const navigateTo = useNavigate();
  const location = useLocation();

  const [checkedIndex, setCheckedIndex] = useState(0);

  const handleClickButton = (index: number, to: string) => {
    setCheckedIndex(index);
    navigateTo(to);
  };

  const taskDetailsPath = /\/tasks\/[0-9a-fA-F-]{36}\/task/;
  if (taskDetailsPath.test(location.pathname)) {
    return null;
  }

  return (
    <Box sx={{ position: 'fixed', bottom: '0px', left: '0', right: '0', background: '#FFFFFF', paddingBottom: '20px' }}>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 16px',
          position: 'relative'
        }}
      >
        {NavBarItems.map((item, index) => (
          <Box
            key={item.label}
            sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}
            onClick={() => handleClickButton(NavBarItems.indexOf(item), item.to)}
          >
            <Box
              sx={{
                width: '125px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                svg: {
                  fill: index === checkedIndex ? '#2D96FF' : '#326CC9'
                }
              }}
            >
              {index === checkedIndex ? item.selectedIcon : item.icon}
            </Box>
            <Text sx={{ fontSize: '10px', color: index === checkedIndex ? '#2D96FF' : '#326CC9' }}>{item.label}</Text>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          top: '-21px',
          background: '#388BFF',
          borderRadius: '100px',
          padding: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 0px 4px 0px rgba(9, 30, 66, 0.20), 0px 8px 16px 0px rgba(9, 30, 66, 0.12)',
          cursor: 'pointer'
        }}
        onClick={() => {
          navigateTo('/scanner');
        }}
      >
        <QRCodeIcon />
      </Box>
    </Box>
  );
};
