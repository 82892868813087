import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Avatar, Button, Text } from 'infinitytechnologies-ui';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import { EDIT_USER_PROFILE, GET_USER_PROFILE, GetProfileQuery, updateUserProfileModel } from '@/logicLayers/domain';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';
import { AuthService } from '@/logicLayers/infrastructure/services';

import {
  ArrowBackIcon,
  BlockInfoWithEdit,
  BlockInfoWithEditItem,
  EditIcon,
  ImageUploader,
  PageTitle,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';
import { LINKS_PAGES } from '@/subsidiaryBinders/constants/links';

import { useTranslation } from '@/i18n';

export const ProfilePage = () => {
  const { t: tProfile } = useTranslation('profile');
  const { t: tCompany } = useTranslation('company');
  const navigateTo = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const { company } = useReduxSelector(selectUserData);

  const { loading: loadingUserProfile, data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache'
  });
  const [EditUserProfile] = useMutation(EDIT_USER_PROFILE);

  const handleLogOut = useCallback(() => {
    AuthService.handleLogOut();
  }, []);

  const handleEditName = useCallback(() => {
    navigateTo(LINKS_PAGES.profileEditName);
  }, []);

  const handleEditPhone = useCallback(() => {
    navigateTo(LINKS_PAGES.profileEditPhone);
  }, []);

  const handleImageUploadOrRemove = (imageKey: string) => {
    if (dataUserProfile) {
      const data = {
        ...updateUserProfileModel(dataUserProfile, { fileKey: imageKey })
      };

      EditUserProfile({
        variables: {
          ...data
        },
        refetchQueries: [GET_USER_PROFILE]
      });
    }
  };

  // ToDo Refactor to Redux Store
  const userFirstName = dataUserProfile?.profile?.firstName;
  const userLastName = dataUserProfile?.profile?.lastName;
  const userEmail = dataUserProfile?.profile?.email;
  const userPhoneNumber = dataUserProfile?.profile?.phoneNumber;
  const userPermissionTag = dataUserProfile?.profile?.permissionTag;
  const userCreatedDate = dayjs(dataUserProfile?.profile?.createdDate);

  const phoneProps = {
    ...(userPhoneNumber ? { descriptionSecond: userPhoneNumber } : { description: tProfile('info.phoneDescription') })
  };

  return loadingUserProfile ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '400px' }} isContainer />
  ) : isSmallScreen || isTablet ? (
    <Box sx={{ width: '100vw', height: '100vh', position: 'relative', overflowX: 'hidden' }}>
      <Box
        sx={{
          height: '210px',
          width: '160vw',
          backgroundColor: '#F5F7FA',
          borderRadius: '0 0 75% 75%',
          position: 'absolute',
          top: '0px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: '-1'
        }}
      />
      <Box sx={{ padding: '12px', position: 'absolute', height: '40px', width: '40px', zIndex: '10' }}>
        <IconButton onClick={() => navigateTo(-1)}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '8px',
          left: '0',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img
          src={company?.logo?.url ? company?.logo?.url : LINKS_IMAGES.profileBackground}
          alt={'Background image'}
          style={{ maxHeight: '124px', width: '302px', objectFit: 'cover' }}
        />
      </Box>
      <Box sx={{ position: 'absolute', top: '145px', left: '50%', transform: 'translateX(-50%)' }}>
        <Box sx={{ position: 'relative' }}>
          <Avatar
            size={'136'}
            src={dataUserProfile?.profile?.profileAvatar?.url}
            sx={{ width: '136px', height: '136px', margin: '0 auto 24px', pointerEvents: 'none' }}
          >
            {userFirstName?.slice(0, 1)}
            {userLastName?.slice(0, 1)}
          </Avatar>
          <Box
            sx={{
              width: '48px',
              height: '48px',
              backgroundColor: '#F5F5F5',
              border: '5px solid #FFFFFF',
              padding: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              position: 'absolute',
              bottom: '-8px',
              right: '0px'
            }}
          >
            <Box>
              <EditIcon sx={{ fontSize: '24px' }} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '300px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '0 16px'
        }}
      >
        <Text sx={{ fontSize: '20px', fontWeight: '600' }}>{`${userFirstName} ${userLastName}`}</Text>
        <Text sx={{ fontSize: '14px', color: '#505864' }}>{userEmail}</Text>
        <Text sx={{ fontSize: '14px', color: '#505864' }}>{userPhoneNumber}</Text>
        <Box
          sx={{
            display: 'flex',
            padding: '20px 12px',
            justifyContent: 'flex-start',
            width: '100%',
            border: '1px solid rgba(41, 49, 62, 0.06)',
            flexDirection: 'column',
            marginTop: '24px'
          }}
        >
          <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{tProfile('mobile.companyTitle')}</Text>
          <Text sx={{ fontSize: '14px', fontWeight: '400', color: '#0C66E4', marginTop: '12px' }}>{company.name}</Text>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '20px 12px',
            justifyContent: 'flex-start',
            width: '100%',
            border: '1px solid rgba(41, 49, 62, 0.06)',
            flexDirection: 'column',
            marginTop: '12px'
          }}
        >
          <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{tProfile('mobile.historyTitle')}</Text>
          <Box sx={{ marginTop: '12px' }}>
            <Text sx={{ fontSize: '14px', fontWeight: '500', color: '#505864' }}>
              {tProfile('mobile.registrationDate')}
            </Text>
            <Text sx={{ fontSize: '16px', fontWeight: '400', marginTop: '12px', color: '#121B2A' }}>
              {userCreatedDate.format('DD MMM YYYY, hh:mm A')}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', marginTop: '46px' }}>
        <Button variant="outlined" size="large" sx={{ padding: '12px 16px', width: '120px' }} onClick={handleLogOut}>
          {tProfile('mobile.logoutBtn')}
        </Button>
      </Box>
    </Box>
  ) : (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} md={10} xl={8} item>
        <PageTitle title={tProfile('title')} subTitle={tProfile('subTitle')} />

        <BlockInfoWithEdit>
          <BlockInfoWithEditItem
            title={tProfile('info.title')}
            label={tProfile('info.photoLabel')}
            description={tProfile('info.photoDescription')}
            Edit={
              <ImageUploader
                path={'USER_AVATAR'}
                imgAlt={`user ${userFirstName} ${userLastName} logo`}
                oldImageUrl={dataUserProfile?.profile?.profileAvatar?.url || ''}
                oldImageFileKey={dataUserProfile?.profile?.fileKey || ''}
                modalTitle={tProfile('info.imageUploader.title')}
                modalTitleToDelete={tProfile('info.imageUploader.titleToDelete')}
                modalSubTitle={tProfile('info.imageUploader.subTitle')}
                allowedExtensionsText={''}
                minDimensionsText={'72px x 72px'}
                maxDimensionsText={''}
                maxFileSizeText={'5'}
                imageMinWidth={72}
                imageMinHeight={72}
                imageMaxSize={5242880} // Bites
                imageAllowedExtensions={['png', 'jpeg', 'jpg', 'svg']}
                imageRequired
                uploaderButtonContent={
                  userFirstName && userLastName ? (
                    <Avatar size={'64'} sx={{ pointerEvents: 'none' }}>
                      {userFirstName.slice(0, 1)}
                      {userLastName.slice(0, 1)}
                    </Avatar>
                  ) : null
                }
                titleContent={
                  <Text className={'m-0'} variant={'bodyMd'} sx={{ color: '#505668', textAlign: 'center' }}>
                    {tCompany('settings.modal.imageUploader.allowedExtensionsText')}{' '}
                    {tProfile('info.imageUploader.titleContent')}
                  </Text>
                }
                modalContent={
                  userFirstName && userLastName ? (
                    <Avatar
                      size={'136'}
                      sx={{ width: '180px', height: '180px', margin: '0 auto 24px', pointerEvents: 'none' }}
                    >
                      {userFirstName.slice(0, 1)}
                      {userLastName.slice(0, 1)}
                    </Avatar>
                  ) : null
                }
                onImageUploadSuccess={handleImageUploadOrRemove}
                onImageDelete={() => {
                  handleImageUploadOrRemove('');
                }}
                onImageUploadError={() => {
                  // ToDo Remove
                  console.log('ImageUploader handleSaveImage uploadedResult error');
                }}
              />
            }
          />

          <BlockInfoWithEditItem
            label={tProfile('info.nameLabel')}
            descriptionSecond={userFirstName + ' ' + userLastName}
            Edit={
              <IconButton onClick={handleEditName}>
                <EditIcon />
              </IconButton>
            }
          />

          <BlockInfoWithEditItem
            label={tProfile('info.phoneLabel')}
            {...phoneProps}
            Edit={
              <IconButton onClick={handleEditPhone}>
                <EditIcon />
              </IconButton>
            }
          />

          <BlockInfoWithEditItem
            label={tProfile('info.roleLabel')}
            description={!userPermissionTag ? tProfile('info.roleDescription') : null}
            descriptionSecond={userPermissionTag || null}
          />

          <BlockInfoWithEditItem label={tProfile('Email')} descriptionSecond={userEmail} />
        </BlockInfoWithEdit>
      </Grid>
    </Grid>
  );
};
