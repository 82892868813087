import { toPng } from 'html-to-image';
import JSZip from 'jszip';

import { EMPTY_CELL_VALUE } from '@/separatedModules/components';

import { i18n } from '@/i18n/config';

import { generateImageFromSvg, generateSvgString, qrCode } from '../../../Providers/helpers';

const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      if (result && typeof result === 'string') {
        resolve(result);
      } else {
        reject(new Error('Failed to convert blob to base64 string.'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

export const generateQRCodeWithText = async (qrValue: string, size: number, systemName: string): Promise<string> => {
  const svgString = generateSvgString(systemName || EMPTY_CELL_VALUE);
  const imageData = await generateImageFromSvg(svgString);

  qrCode.update({
    data: qrValue,
    image: imageData,
    width: size,
    height: size,
    backgroundOptions: {
      color: 'white'
    }
  });

  const blob = await qrCode.getRawData('png');

  if (!blob) {
    throw new Error('Failed to generate QR code blob.');
  }

  const qrDataUrl = await blobToBase64(blob);

  return qrDataUrl;
};

export const createSticker = async (item: any, size: number, breadcrumbsLinks: any[]): Promise<string> => {
  const stickerContainer = document.createElement('div');
  stickerContainer.style.backgroundColor = 'white';
  stickerContainer.style.padding = '17px';
  stickerContainer.style.width = `${size}px`;
  stickerContainer.style.height = `${size}px`;
  stickerContainer.style.display = 'flex';
  stickerContainer.style.flexDirection = 'column';
  stickerContainer.style.justifyContent = 'space-between';

  const titleFontSize = size * 0.0877;
  const spanFontSize = Math.max(0, titleFontSize - 2);

  console.dir(breadcrumbsLinks);

  stickerContainer.innerHTML = `
      <div>
        <h1 style="font-size: ${titleFontSize}px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 250px;">${item?.name}</h1>
        <div style="display: flex; flex-wrap: wrap; margin-top: 4px;">
        ${breadcrumbsLinks
          .map(({ titleFallback }, index) => {
            const isFirstItem = index === 0;

            return `
              <div class="breadcrumbs__item" style="display: flex; align-items: center;">
                ${isFirstItem ? '' : '<span style="margin: 0px 5px;">&gt;</span>'} 
                <p style="margin: 0; font-size: ${spanFontSize}px;">${titleFallback}</p>
              </div>`;
          })
          .join('')}
        </div>
      </div>
      <div>
        <h1 style="font-size: ${titleFontSize}px;">System name</h1>
        <div style="margin-top: 4px;"> 
          <span style="font-size: ${spanFontSize}px;">${item?.systemName}</span>
        </div>
      </div>
    `;

  document.body.appendChild(stickerContainer);
  const stickerPngData = await toPng(stickerContainer, { quality: 1 });
  document.body.removeChild(stickerContainer);

  return stickerPngData;
};

export const addFilesToZip = (
  zip: JSZip,
  folderName: string,
  qrData: string,
  stickerData: string,
  fileName: string
) => {
  const folder = zip.folder(folderName || '');
  folder?.file(`QR_${fileName}.png`, qrData.split(',')[1], { base64: true });
  folder?.file(`SCR_${fileName}.png`, stickerData.split(',')[1], { base64: true });
};

export function getBreadcrumbs(locationFolder: any) {
  const breadcrumbs = [{ link: '/locations', titleFallback: i18n.t('pageTitle', { ns: 'locations' }) }];

  if (locationFolder?.parentsLocationFolders) {
    [...locationFolder.parentsLocationFolders].reverse().forEach((parent: any) => {
      breadcrumbs.push({
        link: `/locations/${parent.id}`,
        titleFallback: parent.name
      });
    });
  }

  if (locationFolder?.name) {
    breadcrumbs.push({
      link: '',
      titleFallback: locationFolder?.name
    });
  }

  return breadcrumbs;
}
