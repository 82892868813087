import { BadgeIcon, DescriptionIcon, DomainIcon, EquipmentWheelsIcon } from '@/separatedModules/components';

import { LINKS_PAGES, LinksPagesT } from './index';

export const LINKS_SUPER_ADMIN_HEADER_MENU = (isSuperAdmin?: boolean): LinksPagesT[] => [
  {
    id: 100,
    order: 100,
    link: LINKS_PAGES.locations,
    title: 'header.menu.locations',
    isHidden: isSuperAdmin || false
  },
  {
    id: 101,
    order: 101,
    link: LINKS_PAGES.role,
    title: 'header.menu.role',
    isHidden: false
  },
  {
    id: 102,
    order: 102,
    link: LINKS_PAGES.companies,
    title: 'header.menu.companies',
    isHidden: false
  },
  {
    id: 103,
    order: 103,
    link: LINKS_PAGES.users,
    title: 'header.menu.users',
    isHidden: false
  },
  {
    id: 104,
    order: 104,
    link: LINKS_PAGES.tasks,
    title: 'header.menu.tasks',
    isHidden: isSuperAdmin || false
  },
  {
    id: 105,
    order: 105,
    link: LINKS_PAGES.templatesEquipment,
    title: 'header.menu.templates.title',
    isHidden: false
  }
];

export const LINKS_HEADER_MENU = (
  isUserCompanyAdmin: boolean,
  permissions: ({ __typename?: 'PermissionDto' | undefined; name: string } | null)[]
): LinksPagesT[] => [
  {
    id: 100,
    order: 100,
    link: LINKS_PAGES.locations,
    title: 'header.menu.locations',
    isHidden: !permissions.some(
      (perm: { __typename?: 'PermissionDto' | undefined; name: string } | null) =>
        perm?.name === 'ROLE_LOCATIONS_VIEWING'
    )
  },
  {
    id: 101,
    order: 101,
    link: LINKS_PAGES.role,
    title: 'header.menu.role',
    isHidden: !permissions.some(
      (perm: { __typename?: 'PermissionDto' | undefined; name: string } | null) =>
        perm?.name === 'ROLE_PERMISSIONS_VIEWING'
    )
  },
  {
    id: 102,
    order: 102,
    link: LINKS_PAGES.users,
    title: 'header.menu.users',
    isHidden: !permissions.some(
      (perm: { __typename?: 'PermissionDto' | undefined; name: string } | null) => perm?.name === 'ROLE_USERS_VIEWING'
    )
  },
  {
    id: 103,
    order: 103,
    link: LINKS_PAGES.tasks,
    title: 'header.menu.tasks',
    isHidden: !permissions.some(
      (perm: { __typename?: 'PermissionDto' | undefined; name: string } | null) => perm?.name === 'ROLE_TASKS_VIEWING'
    )
  },
  {
    id: 104,
    order: 104,
    link: LINKS_PAGES.templatesEquipment,
    title: 'header.menu.templates.title',
    isHidden: !permissions.some(
      (perm: { __typename?: 'PermissionDto' | undefined; name: string } | null) =>
        perm?.name === 'ROLE_EQUIPMENTS_VIEWING'
    ),
    children: [
      {
        id: 1031,
        order: 1031,
        link: LINKS_PAGES.templatesEquipment,
        title: 'header.menu.templates.equipment',
        isHidden: false,
        icon: <EquipmentWheelsIcon />
      },
      {
        id: 1032,
        order: 1032,
        link: LINKS_PAGES.templatesRoles,
        title: 'header.menu.templates.roles',
        isHidden: isUserCompanyAdmin,
        icon: <BadgeIcon />
      },
      {
        id: 1033,
        order: 1033,
        link: LINKS_PAGES.templatesReports,
        title: 'header.menu.templates.reports',
        isHidden: false,
        isDisabled: true,
        icon: <DescriptionIcon />
      },
      {
        id: 1034,
        order: 1034,
        link: LINKS_PAGES.templatesDomains,
        title: 'header.menu.templates.domains',
        isHidden: false,
        icon: <DomainIcon />
      }
    ]
  }
];
