import { ReactNode } from 'react';

import { ObjectStringValuesT } from '@/subsidiaryBinders/types';

export type LinksBreadcrumbsT = {
  link?: string;
  title?: string;
  titleFallback?: string;
};

export type LinksPagesT = {
  id: number;
  order: number;
  link: string;
  title: string;
  isHidden: boolean;
  children?: LinksPagesT[];
  icon?: ReactNode;
  isDisabled?: boolean;
};

export const LINKS_PAGES: ObjectStringValuesT = {
  about: '/about',
  /* ——— companies ——— */
  companies: '/companies',
  scanner: '/scanner',
  companiesItem: '/companies/:folderId',
  companiesArchive: '/companies/archive',
  companiesRecent: '/companies/recent',
  companiesStarred: '/companies/starred',
  /* ——— company ——— */
  company: '/company',
  companyCreate: '/company/create',
  companyItem: '/company/:companyId',
  companyItemEdit: '/company/:companyId/edit',
  companyItemSettings: '/company/:companyId/settings',
  /* ——— home ——— */
  home: '/',
  locations: '/locations',
  locationsArchive: '/locations/archive',
  locationsItem: '/locations/:folderId',
  locationsRecent: '/locations/recent',
  locationsStarred: '/locations/starred',
  notFound: '*',
  overview: '/overview',
  payments: '/payments',
  /* ——— profile ——— */
  profile: '/profile',
  profileMobile: '/profile-mobile',
  profileSecurity: '/profile/security',
  profileEdit: '/profile/edit',
  profileEditName: '/profile/edit?q=name',
  profileEditPassword: '/profile/edit?q=password',
  profileEditPhone: '/profile/edit?q=phone',
  /* ——— sign-in ——— */
  signIn: '/sign-in',
  /* ——— tasks ——— */
  tasks: '/tasks',
  tasksCreate: '/tasks/create',
  tasksDetails: '/tasks/:taskId/task',
  tasksCustom: '/tasks/custom',
  tasksCustomDetails: '/tasks/custom/:taskId/task',
  tasksAccomplished: '/tasks/accomplished',
  requestOnHold: '/tasks/:taskId/request-on-hold',
  /* ——— equipment ——— */
  equipmentItemCreate: '/equipment/create/:locationId',
  equipmentItemParameters: '/locations/equipment/:templateId/parameters',
  equipmentItemParameter: '/locations/equipment/:templateId/parameters/:parameterId',
  equipmentItemRoundRegulations: '/locations/equipment/:templateId/round-regulations',
  equipmentItemRoundRegulation: '/locations/equipment/:templateId/round-regulations/:parameterId',
  equipmentItemTasks: '/locations/equipment/:templateId/tasks',
  /* ——— notifications ——— */
  notifications: '/notifications',
  /* ——— templates ——— */
  templates: '/templates',
  /* ——— templates/equipment ——— */
  templatesEquipment: '/templates/equipment',
  templatesEquipmentCreate: '/templates/equipment/create',
  templatesEquipmentItem: '/templates/equipment/item/:templateId',
  equipmentModel: '/locations/equipment/:templateId',
  equipmentModelEdit: '/locations/equipment/:templateId/edit',
  templatesEquipmentItemEdit: '/templates/equipment/item/:templateId/edit',
  templatesEquipmentItemDetails: '/templates/equipment/item/:templateId/details',
  templatesEquipmentModelDetails: '/locations/equipment/:templateId/details',
  templatesEquipmentItemItems: '/templates/equipment/item/:templateId/items',
  templatesEquipmentItemDocuments: '/templates/equipment/item/:templateId/documents',
  templatesEquipmentItemParameters: '/templates/equipment/item/:templateId/parameters',
  templatesEquipmentItemParameter: '/templates/equipment/item/:templateId/parameter/:parameterId',
  templatesEquipmentItemParameterEdit: '/templates/equipment/item/:templateId/parameter/:parameterId/edit',
  templatesParametersCreate: '/templates/parameters/create/:templateId',
  templatesRoundRegulationCreate: '/templates/round-regulation/create/:templateId',
  templatesTaskByRoundCreate: '/templates/:templateId/task-by-round/create/:roundId',
  /* ToDo Start  */
  templatesEquipmentItemRoundsRoundRegulations: '/templates/equipment/item/:templateId/rounds/round-regulations',
  templatesEquipmentEditItemRoundsTask: '/templates/equipment/item/:templateId/roundTask/:roundRegulationId/edit',
  templatesEquipmentEditItemRoundsRoundRegulations:
    '/templates/equipment/item/:templateId/roundRegulation/:roundRegulationId/edit',
  templatesEquipmentItemRoundsRoundRegulation:
    '/templates/equipment/item/:templateId/rounds/round-regulations/:parameterId',
  /* ToDo End */
  /* ——— templates/role ——— */
  templatesRoles: '/templates/role',
  templatesRolesCreate: '/templates/role/create',
  // ToDo Refactor
  templatesRolesRoleEdit: '/templates/role/edit/:templateId',
  templatesRolesRoleDetails: '/templates/role/details/:templateId',
  templatesRolesRoleDetailsEdit: '/templates/role/details/edit/:templateId',
  templatesRolesRolePermissions: '/templates/role/permissions/:templateId',
  /* ——— templates/reports ——— */
  templatesReports: '/templates/reports',
  /* ——— templates/domains ——— */
  templatesDomains: '/templates/domains',
  /* ——— templates/recent ——— */
  templatesRecent: '/templates/recent',
  /* ——— templates/starred ——— */
  templatesStarred: '/templates/starred',
  /* ——— templates/archive ——— */
  templatesArchive: '/templates/archive',
  /* ——— ui ——— */
  ui: '/ui',
  uiButtons: '/ui/buttons',
  uiIcons: '/ui/icons',
  uiInputs: '/ui/inputs',
  uiLib: '/ui/lib',
  uiModals: '/ui/modals',
  uiOther: '/ui/other',
  uiRadioSwitchCheckbox: '/ui/radio-switch-checkbox',
  uiTextEditors: '/ui/text-editors',
  /* ——— user ——— */
  user: '/user',
  userItem: '/user/:userId',
  userItemVisibility: '/user/:userId/visibility',
  userItemEdit: '/user/:userId/edit',
  userItemManagement: '/user/:userId/management',
  userItemManagementVisibility: '/user/:userId/management/visibility',
  userItemSecurity: '/user/:userId/security',
  userItemPermissions: '/user/:userId/permissions',
  userItemPermissionsVisibility: '/user/:userId/permissions/visibility',
  userItemPermissionsManagement: '/user/:userId/permissions/management',
  /* ——— users ——— */
  // ToDo Refactor
  // ToDo Refactor
  users: '/users',
  role: '/role',
  usersRole: '/role/:roleId/users',
  // ToDo Refactor
  // ToDo Refactor
  users2: '/users2',
  usersArchive: '/users/archive',
  usersRecent: '/users/recent',
  usersStarred: '/users/starred'
};

export const LINKS_BREADCRUMBS: LinksBreadcrumbsT[] = [
  {
    link: LINKS_PAGES.home,
    title: 'home'
  }
];
